import React, { FC } from 'react';
import classNames from 'classnames';
import Tag from 'components/tag';
import Icon from 'components/icon';
import { href_new_post as hrefNewPost } from 'components/href-helper';
import './post-entry.less';
import loginChecker from 'components/util/login-checker';
import Route from 'components/util/route';


interface PostEntryProps {
  /** 是否是圆形 */
  isCircle: boolean;
  /** 是否渐进 */
  fadeIn: boolean;
  /** 是否允许游客访问 */
  allowTourist: boolean;
}
const PostEntry:FC<PostEntryProps> = ({ isCircle, fadeIn, allowTourist }) => {
  const onEntryPost = () => {
    loginChecker.check()
      .then((res) => {
        const href = hrefNewPost(res.data.user_id, allowTourist);
        Route.push(href);
      });
  };

  return (
    <Tag.button
      className={classNames('post-entry', { 'post-entry--active': isCircle, 'post-entry--show': fadeIn })}
      needAuth
      onClick={onEntryPost}
      style={{ color: '#fff' }}
    >
      <Icon type="pen-white" className="post-entry__icon" />
      <Tag.span isText className="post-entry__content">我要发言</Tag.span>
    </Tag.button>
  );
};

export { PostEntry };
