import './change-log-entry.less';
import Tag from 'components/tag';
import React, { FC, useState } from 'react';
import changeLogIcon from '../img/change-log-icon.png';
import ChangeLogEntryLogo from 'components/IconSystem/change-log-entry.svg';
import { routeToChangeLog } from 'components/href-helper';
import useEffectOnce from 'components/hooks/useEffectOnce';
import { get_api_products_menu as getApiProductsMenu } from 'components/api';
import { IconChangeLogEntry } from 'components/IconSystem/index';

const cls = 'change-log-entry';
interface ChangeLogEntryProps {
  productId: string | number;
}
const ChangeLogEntry: FC<ChangeLogEntryProps> = (props) => {
  const [show, setShow] = useState(false);
  useEffectOnce(() => {
    getApiProductsMenu(props.productId)
      .then((res) => {
        if (res.data && res.data['feat-log']) {
          setShow(true);
        }
      });
  });
  if (!show) return null;

  return (
    <Tag.a className={`${cls}`} href={routeToChangeLog()}>
      <IconChangeLogEntry />
      <Tag.span className={`${cls}__text`}>更新日志</Tag.span>
    </Tag.a>
  );
};

export default ChangeLogEntry;
