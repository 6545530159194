import Sentry from 'components/sentry';
import { throttle, isEmpty } from 'lodash-es';
import React, { Component, createRef } from 'react';
import {
  get_api_faqs_categories_list as getApiFaqsCategoriesList,
  get_api_faqs_categories_list_categoryId as getApiFaqsCategoriesListCategoryId,
  get_api_account as getApiAccount,
  getApiV3RoadmapSettingStatus,
} from 'components/api';
import Tag from 'components/tag';
import BasePage from 'components/base-page-mobile';
import { FaqsCard } from 'components/faqs-card';
import { PostList } from 'components/post-item/post-list.mobile';
import { getProductId, sessionStorage, getUrlParams } from 'components/util';
import Banner from './common/banner';
import imgBanner from './img/embed_index_banner_tiny.jpg';
import { PostEntry } from './common/post-entry';
import ChangeLogEntry from './common/change-log-entry';
import Notification from 'components/notification';
import { TcIconCE, TcIconReturn, TcIconClosure } from 'components/IconSystem';
import { LOGIN_TYPE, ACCESS_TYPE } from 'components/constants/user';
import 'components/css/mobile/reset.less';
import './style.less';
import { DISPLAY_MODE } from 'components/constants/app';
import Route from 'components/util/route';
import { hrefLargeHome } from 'components/href-helper';
import PostAd from 'components/ad-resources/post-ad';
import { AD_INDEXES } from 'components/api/wjapi';
import { BannerAd } from 'components/ad-resources/banner-ad';

// 隐藏掉ce提示
const HIDE_CE_TIPS = '1';
class App extends Component {
  constructor(props) {
    super(props);

    this.productId = getProductId();
    this.postList = createRef();

    this.state = {
      faqList: [],
      isCircle: false,
      fadeIn: false,
      bannerUrl: null,
      isDefaultBanner: false,
      productName: null,
      isCEProduct: false,
      isInternalCEProduct: false,
      isInternalStaff: false,
      faqClassifyList: [],
      loginProvider: {
        allow: [],
        deny: [],
      },
      canLayoutScroll: true,
      showTopic: true, // 话题设置开关
    };
    this.showCeTips = getUrlParams().get('hide_ce_tips') !== HIDE_CE_TIPS;
    this.scrollEfect = throttle(this.scrollEfect.bind(this));
    this.updatePostListByAction = this.updatePostListByAction.bind(this);
    this.changeCanLayoutScroll = this.changeCanLayoutScroll.bind(this);
    this.handleCloseTopBar = this.handleCloseTopBar.bind(this);
  }

  componentDidMount() {
    this.getFaqs();
    this.subscribeNotice();
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  // 小程序需要使用 Taro.createRef 所以这里单独处理
  notice = createRef();

  // 更新帖子列表, 提供给小程序onShow时动态更新被操作的帖子
  updatePostListByAction() {}

  changeCanLayoutScroll(canLayoutScroll) {
    this.setState({ canLayoutScroll });
  }

  // 消息通知订阅逻辑
  subscribeNotice() {
    // 判断是否小程序环境，小程序不显示弹窗
    const isMiniProgram = /miniprogram/i.test(navigator.userAgent);
    if (isMiniProgram) return;

    // 获取旧工程发帖时写进 session 的数据
    const SessionPostData = JSON.parse(sessionStorage.getItem('SessionPostData'));

    if (
      !SessionPostData
      || !SessionPostData.actionData
      || !SessionPostData.noticeData
    ) {
      return;
    }

    // 避免下次打开首页又莫名其妙出现弹窗
    sessionStorage.removeItem('SessionPostData');
    const {
      actionData: { link },
      noticeData: {
        result: {
          data: [post],
        },
      },
    } = SessionPostData;
    const { id } = post;

    setTimeout(() => {
      this.notice.current && this.notice.current.subscribePost(id, link);
    }, 1000);
  }

  handleProductInfo = (productInfo) => {
    let bannerUrl = productInfo.banner_images.header_image_mobile;
    const productName = productInfo.product_name;

    // 如果是大产品则跳转到大产品首页
    if (productInfo.display_mode === DISPLAY_MODE.LARGE && process.env.TARO_ENV !== 'weapp') {
      Route.push(hrefLargeHome());
      return;
    }
    // 侗艺定制 banner TODO: 看用户需求量，考虑整成定制化功能
    if (Number(this.productId) === 113810) {
      bannerUrl = 'https://txc.qq.com/data/4442/2020/0107/bf2d6466e7c260c5314cadfbebf6843f.jpeg';
    }

    const isCEProduct = productInfo.access_type === ACCESS_TYPE.INTERNAL && productInfo.is_tencent_product === true;

    const isInternalCEProduct = productInfo.access_type === ACCESS_TYPE.EXTERNAL
    && productInfo.is_tencent_product === true;

    this.setState({
      bannerUrl: bannerUrl || imgBanner,
      productName,
      isCEProduct,
      isInternalCEProduct,
      isDefaultBanner: !bannerUrl,
      loginProvider: productInfo.login_provider,
    }, () => {
      this.getAccount();
    });
  };

  // 获取常见问题列表 和博客
  getFaqs() {
    const { productId } = this;

    // 获取常见问题-分类-获取第一级分类列表
    getApiFaqsCategoriesList({ productId }).then((res) => {
      const { data } = res;
      const newList = [...data.list];
      if (data.info.total > 1) {
        data.list.forEach((item, i) => {
          if (item.code === 'default_node' && item.title === '') {
            newList[i].title = '默认分类';

            if (item.child_info.length === 0) {
              newList.splice(i, 1);
            }
          }
        });

        this.setState({
          faqClassifyList: newList,
        });
      }

      if (data.info.total === 1 && data.info.type === 'category') {
        getApiFaqsCategoriesListCategoryId({
          productId,
          categoryId: data.list[0].id,
        }).then((res) => {
          this.setState({ faqList: res.data.list });
        });
      }
    });

    setTimeout(() => {
      this.setState({
        fadeIn: true,
      });
    }, 1000);
  }

  scrollEfect(el) {
    if (!el || !el.target) {
      return;
    }
    const { target } = el;
    const critical = window.screen.availHeight * 2;
    const { isCircle } = this.state;
    if (target.scrollTop > critical && !isCircle) {
      this.setState({
        isCircle: true,
      });
    } else if (target.scrollTop <= critical && isCircle) {
      this.setState({
        isCircle: false,
      });
    }
    // 触底时 显示回正常button
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.setState({
        isCircle: false,
      });
    }
  }

  /** 获取登录人信息 */
  getAccount() {
    getApiAccount().then((res) => {
      const { state } = this;
      const isInternalStaff = state.isInternalCEProduct && res.data.login_type === LOGIN_TYPE.OA;
      this.setState({ isInternalStaff });
    });
  }

  // 获取产品的话题设置
  getProductTopicSettingApi() {
    const { productId } = this;
    getApiV3RoadmapSettingStatus({ productId })
      .then((res) => {
        if (res.status === 0) {
          this.setState({ showTopic: res.data?.status === 'on' });
        }
      });
  }

  handleCloseTopBar() {
    const { state } = this;
    this.setState({ isInternalStaff: !state.isInternalStaff });
  }

  render() {
    const {
      faqList,
      canLayoutScroll,
      faqClassifyList,
      isCircle,
      fadeIn,
      loginProvider,
      productName,
      isCEProduct,
      isInternalStaff,
      showTopic,
      isDefaultBanner,
    } = this.state;
    if (faqList.length > 5) {
      faqList.length = 5;
    }
    const showFaq = Boolean(faqClassifyList.length) || Boolean(faqList.length);
    const { allow, deny } = loginProvider;
    const allowTourist = (isEmpty(allow) && isEmpty(deny))
      || (isEmpty(allow) && deny.indexOf('system') === -1)
      || allow.indexOf('system') >= 0;
    return (
      <>
        <Notification ref={this.notice} />
        <BasePage
          canLayoutScroll={canLayoutScroll}
          onScroll={this.scrollEfect}
          setProductInfo={this.handleProductInfo}
        >
          <Tag.div className="page-general">
            {this.showCeTips && isCEProduct && <Tag.div className="page-general__top page-general__top-ce">
              <Tag.a className="page-general__top-ce--href" href="/enterprise/1/home">
                <TcIconReturn className="page-general__top--icon retureicon" />
                <TcIconCE className="page-general__top--icon ceicon" />
                腾讯惜议
              </Tag.a>
            </Tag.div>}
            {isInternalStaff && <Tag.div className="page-general__top page-general__top-isInternal">
              Q哥Q妹，前往<Tag.a className="page-general__top-isInternal--isInternal" href={`/enterprise/1/search?keyword=${productName}`}>惜议反馈</Tag.a>，回复更快速！
              <TcIconClosure onClick={this.handleCloseTopBar} className="page-general__top--icon closure" />
            </Tag.div>}
            <Tag.div className="page-general__top-area">
              <Banner
                productId={this.productId}
                bannerUrl={this.state.bannerUrl}
                isDefaultBanner={isDefaultBanner}
              />
              <ChangeLogEntry productId={this.productId} />
            </Tag.div>
            <PostEntry
              isCircle={isCircle}
              fadeIn={fadeIn}
              allowTourist={allowTourist}
            />
            {showFaq && (
              <FaqsCard list={faqList} faqClassifyList={faqClassifyList} />
            )}
            <PostAd indexes={AD_INDEXES.HOME4} />
            <BannerAd productId={this.productId} />
            <PostList
              ref={this.postList}
              showTopic={showTopic}
              productId={this.productId}
              allowTourist={allowTourist}
              onShowMore={this.changeCanLayoutScroll}
            />
          </Tag.div>
        </BasePage>
      </>
    );
  }
}

export { App };
