import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from 'components/tag';
import { TcIconFaqHot, TcIconNext } from 'components/IconSystem';
import { FaqClassifyIcon } from 'components/faq-classify-icon';
import {
  href_faqs_more as hrefFaqsMore,
  href_faqs_detail as hrefFaqsDetail,
  href_faqs_category as hrefFaqsCategory,
  href_faqs_list as hrefFaqsList,
} from 'components/href-helper';
import { giveLikeNumber } from 'components/util';

import './style.less';

const getFaqHref = (faq) => {
  if (faq.child_info.type === 'category') {
    return hrefFaqsCategory(faq.id);
  }

  return hrefFaqsList(faq.id);
};
const FaqsCard = (props) => {
  const { list } = props;

  let hasFaqClassify = Boolean(props.faqClassifyList.length);

  if (props.faqClassifyList.length === 1 && props.faqClassifyList[0].title === '默认分类') {
    hasFaqClassify = false;
  }


  return (
    <Tag.div className="faqs-card">
      <Tag.div className="faqs-card__header">
        <Tag.h3 className="faqs-card__title auto-focus">{props.title}</Tag.h3>
        {props.faqClassifyList.length <= 1 && (
        <Tag.a target={props.target} className="faqs-card__all" href={hrefFaqsMore()}>
          全部
        </Tag.a>
        )}
      </Tag.div>
      <Tag.div className={classNames('faqs-card__pro', { 'faqs-card__classify': props.faqClassifyList.length })}>
        {!hasFaqClassify ? (
          <Tag.ul className="faqs-card__pro-list">
            {list.map(item => (
              <Tag.li className="faqs-card__item" key={item.id}>
                {Boolean(item.solved_count) && (
                <Tag.div className="solved_num">{giveLikeNumber(item.solved_count)} 有用</Tag.div>
                )}

                <Tag.a className="faqs-card__link" target={props.target} href={hrefFaqsDetail(item.faq_id)}>
                  <Tag.div className="faqs-card__item-title">{item.title}</Tag.div>
                  {item.is_hot && <TcIconFaqHot />}
                </Tag.a>
              </Tag.li>
            ))}
            <Tag.a className="see_more" href={hrefFaqsMore()}>
              查看更多 {'>'}
            </Tag.a>
          </Tag.ul>
        ) : (
          <Tag.ul className="faqs-card__classify-list">
            {props.faqClassifyList.map((item, i) => {
              if (item.code === 'default_node' && item.title === '') {
                item.title = '默认分类';
              }

              return (
                <Tag.li className="faqs-card__item" key={i}>
                  <Tag.a className="faqs-card__link" target={props.target} href={getFaqHref(item)}>
                    <FaqClassifyIcon setting={item.setting} />
                    <Tag.div className="faqs-card__item-title">{item.title}</Tag.div>
                    <TcIconNext />
                  </Tag.a>
                </Tag.li>
              );
            })}
          </Tag.ul>
        )}
      </Tag.div>
    </Tag.div>
  );
};


FaqsCard.defaultProps = {
  list: [],
  title: '常见问题',
  type: '',
  faqClassifyList: [],
  nextFaqClassifyList: [],
  isPc: false,
  faqClassifyWidth: '100%',
};
FaqsCard.propTypes = {
  list: PropTypes.array,
  faqClassifyList: PropTypes.array,
  target: PropTypes.string,
  title: PropTypes.string,
};

export { FaqsCard };
