import React, { FC } from 'react';
import classnames from 'classnames';
import Tag from 'components/tag';
import './style.less';
interface GeneralBannerProps {
  bannerUrl: string | null;
  isDefaultBanner: boolean;
}
const GeneralBanner: FC<GeneralBannerProps> = ({ bannerUrl, isDefaultBanner }) => (
  <Tag.div
    // banner不读
    aria-hidden={true}
    className={classnames('general-banner', {
      'general-banner--fade-in': bannerUrl,
      'general-banner--animated': bannerUrl,
    })}
  >
    {isDefaultBanner && (<Tag.span className="general-banner__large-text">期待你的反馈</Tag.span>)}
    {isDefaultBanner && (<Tag.span className="general-banner__small-text">Offer your great opinion</Tag.span>)}
    <Tag.img mode="widthFix" className={classnames('general-banner__img', {
      // 默认图片的文字使用伪类添加
      'general-banner__img--default': isDefaultBanner,
    })} src={bannerUrl}
    />
  </Tag.div>
);

export default GeneralBanner;
