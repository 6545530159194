// actions
export const SET_USER_ID = 'SET_USER_ID';

// login type
/** @typedef {{ QQ: 1, QA: 2 }} LOGIN_TYPE */
export const LOGIN_TYPE = Object.freeze({
  /** @docs 1 qq或微信登陆 暂时都是1 */
  QQ: 1,
  /** @docs 2 oa登陆 */
  OA: 2,
});

// 产品类型
export const ACCESS_TYPE = Object.freeze({
  /** @docs 1 外部 */
  EXTERNAL: 1,
  /** @docs 2 内部 */
  INTERNAL: 2,
});
